@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Roboto:ital,wght@0,100..900;1,100..900&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@font-face {
  font-family: "Montserrat Arabic";
  font-weight: 900;
  src: url("../fonts/montserrat-arabic/montserrat-arabic-black.ttf");
}

@font-face {
  font-family: "Montserrat Arabic";
  font-weight: 800;
  src: url("../fonts/montserrat-arabic/montserrat-arabic-extrabold.ttf");
}

@font-face {
  font-family: "Montserrat Arabic";
  font-weight: 700;
  src: url("../fonts/montserrat-arabic/montserrat-arabic-bold.ttf");
}

@font-face {
  font-family: "Montserrat Arabic";
  font-weight: 600;
  src: url("../fonts/montserrat-arabic/montserrat-arabic-semibold.ttf");
}

@font-face {
  font-family: "Montserrat Arabic";
  font-weight: 500;
  src: url("../fonts/montserrat-arabic/montserrat-arabic-medium.ttf");
}

@font-face {
  font-family: "Montserrat Arabic";
  font-weight: 400;
  src: url("../fonts/montserrat-arabic/montserrat-arabic-regular.ttf");
}

@font-face {
  font-family: "Montserrat Arabic";
  font-weight: 300;
  src: url("../fonts/montserrat-arabic/montserrat-arabic-light.ttf");
}

@font-face {
  font-family: "Montserrat Arabic";
  font-weight: 200;
  src: url("../fonts/montserrat-arabic/montserrat-arabic-extralight.ttf");
}

@font-face {
  font-family: "Montserrat Arabic";
  font-weight: 100;
  src: url("../fonts/montserrat-arabic/montserrat-arabic-thin.ttf");
}

* {
  font-family: "Montserrat Arabic", sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: none;
}

p {
  margin: 0;
}

center {
  width: 100%;
}

html,
body {
  background: #f2f2f4;
  line-height: 1.5;
  color: #0e072f;
}

input::-moz-placeholder {
  color: #4f4a68;
}

input:-ms-input-placeholder {
  color: #4f4a68;
}

input::-webkit-input-placeholder {
  color: #4f4a68;
}
